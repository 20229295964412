var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('DefaultLayout',{staticClass:"order",attrs:{"breadcrumbs":[{ name: 'Заявки', value: 'orders' }],"isHideFiltersPanel":true,"heading":"Заявка на перегон автомобиля"}},[_c('ValidationObserver',{ref:"form",staticClass:"maintenance-order-create order-form",attrs:{"tag":"form"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('div',{staticClass:"order-form__container sp-xl"},[_c('RequestFormPartWrapper',{attrs:{"name":"Услуга","number":"01"}},[_c('Paragraph',[_vm._v(_vm._s(_vm.form.serviceTitle))])],1),_c('RequestFormPartWrapper',{attrs:{"name":"Автомобиль","number":"02"}},[_c('CarFormPart',{attrs:{"id":_vm.form.carId,"isTO":true},on:{"update:id":function($event){return _vm.$set(_vm.form, "carId", $event)}}})],1),_c('RequestFormPartWrapper',{attrs:{"name":"Место и дата","number":"03"}},[_c('div',{staticClass:"order-form__city-and-date--grid sp-sm"},[_c('ValidationProvider',{staticClass:"order-form__city",attrs:{"tag":"div","rules":"required","name":"transferAddress"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('TextInput',{attrs:{"required":true,"error":errors[0],"label":"Адрес передачи*","hide-details":"","small":true},model:{value:(_vm.form.transferAddress),callback:function ($$v) {_vm.$set(_vm.form, "transferAddress", $$v)},expression:"form.transferAddress"}},[_c('Paragraph',{staticClass:"display-contents",attrs:{"tag":"span","size":"label","theme":"color-inherit"}},[_vm._v("Адрес передачи"),_c('b',[_vm._v("*")])])],1)]}}],null,true)}),_c('ValidationProvider',{staticClass:"order-form__date form__field--date",attrs:{"tag":"div","rules":"required","name":"date"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('DatePicker',{attrs:{"range":false,"label":"Дата*","required":true,"error":errors[0],"small":true,"isNotPrevDate":true},model:{value:(_vm.form.date),callback:function ($$v) {_vm.$set(_vm.form, "date", $$v)},expression:"form.date"}},[_c('Paragraph',{staticClass:"display-contents",attrs:{"tag":"span","size":"label","theme":"color-inherit"}},[_vm._v("Дата"),_c('b',[_vm._v("*")])])],1)]}}],null,true)})],1),_c('ValidationProvider',{staticClass:"full-width",attrs:{"tag":"div","rules":"required","name":"receivingAddress"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('TextInput',{attrs:{"required":true,"error":errors[0],"label":"Адрес назначения*","small":true},model:{value:(_vm.form.receivingAddress),callback:function ($$v) {_vm.$set(_vm.form, "receivingAddress", $$v)},expression:"form.receivingAddress"}},[_c('Paragraph',{staticClass:"display-contents",attrs:{"tag":"span","size":"label","theme":"color-inherit"}},[_vm._v("Адрес назначения"),_c('b',[_vm._v("*")])])],1)]}}],null,true)})],1),_c('RequestFormPartWrapper',{attrs:{"name":"Передает<b class='color-red'>*</b>","number":"04"}},[_c('CharacterCard',{attrs:{"isAutoComplete":false,"formPartId":"04","data":_vm.form.sender,"formPartName":"Передаёт"},on:{"update:data":function($event){return _vm.$set(_vm.form, "sender", $event)}}})],1),_c('RequestFormPartWrapper',{attrs:{"name":"Принимает<b class='color-red'>*</b>","number":"05"}},[_c('CharacterCard',{attrs:{"isAutoComplete":false,"formPartId":"05","data":_vm.form.receiver,"formPartName":"Принимает"},on:{"update:data":function($event){return _vm.$set(_vm.form, "receiver", $event)}}})],1),(_vm.isShowComment)?_c('RequestFormPartWrapper',{staticClass:"comments",attrs:{"name":"Комментарий","isCloseble":true},on:{"close":_vm.handleCloseComment}},[_c('TextInput',{staticClass:"full-width",attrs:{"label":"Комментарий","hide-details":"","small":true,"maxlength":"500","counter":"","rules":[_vm.rules.count]},model:{value:(_vm.form.comment),callback:function ($$v) {_vm.$set(_vm.form, "comment", $$v)},expression:"form.comment"}})],1):_vm._e()],1),_c('div',{staticClass:"order-form__container"},[_c('ControlsRequestFormPart',{on:{"submit":_vm.handleSubmit}},[(!_vm.isShowComment)?_c('Link',{attrs:{"tag":"div","mod":"dark-blue","icon":"mdi-message-processing"},on:{"click":function($event){_vm.isShowComment = true}}},[_vm._v("Добавить комментарий")]):_vm._e()],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }